import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/segnalazione/:token/crea',
      name: 'submitReport',
      component: () => import('@/views/segn/AnonSegn.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/segnalazione/:token/:uuid',
      name: 'anonReport',
      component: () => import('@/views/segn/AnonSegnShow.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/segnalazione-courtesy/:token/:uuid',
      name: 'anonReportCourtesy',
      component: () => import('@/views/segn/CourtesyPage.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/auth/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: () => import('@/views/auth/ForgotPassword-v2.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/resetPassword/:token/:firstLogin?',
      name: 'resetPassword',
      component: () => import('@/views/auth/ResetPassword-v2.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/activationResend',
      name: 'activationResend',
      component: () => import('@/views/auth/activationResend.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        // breadcrumb: [
        //   {
        //     text: 'Home',
        //     active: true,
        //   },
        // ],
      },
      children: [
        {
          path: 'all',
          name: 'all-segnalazioni',
          meta: {
            breadcrumb: [
              {
                text: 'Tutte',
                active: true,
              },
            ],
          },
        },
        {
          path: 'open',
          name: 'open-segnalazioni',
          meta: {
            breadcrumb: [
              {
                text: 'Aperte',
                active: true,
              },
            ],
          },
        },
        {
          path: 'new',
          name: 'new-segnalazioni',
          meta: {
            breadcrumb: [
              {
                text: 'Nuove',
                active: true,
              },
            ],
          },
        },
        {
          path: 'awaits',
          name: 'awaits-segnalazioni',
          meta: {
            breadcrumb: [
              {
                text: 'In scadenza',
                active: true,
              },
            ],
          },
        },
        {
          path: 'scadenza',
          name: 'scadenza-segnalazioni',
          meta: {
            breadcrumb: [
              {
                text: 'In scadenza',
                active: true,
              },
            ],
          },
        },
        {
          path: 'closed',
          name: 'closed-segnalazioni',
          meta: {
            breadcrumb: [
              {
                text: 'Chiuse',
                active: true,
              },
            ],
          },
        },
      ],
    },
    {
      path: '/segnalazione/:uuid',
      name: 'report',
      component: () => import('@/views/segn/Segnalazione.vue'),
      meta: {
        pageTitle: 'Segnalazione',
        breadcrumb: [
          {
            text: 'Segnalazione',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/user/Profile.vue'),
      meta: {
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/admin/Users.vue'),
      meta: {
        pageTitle: 'Utenti',
        // breadcrumb: [
        //   {
        //     text: 'Utenti',
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: '/createUser',
      name: 'createUser',
      component: () => import('@/views/admin/createUser.vue'),
      meta: {
        pageTitle: 'Utente',
        // breadcrumb: [
        //   {
        //     text: 'Utenti',
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: '/subscription',
      name: 'subscription',
      component: () => import('@/views/admin/Subscription.vue'),
      meta: {
        pageTitle: 'Abbonamento',
        // breadcrumb: [
        //   {
        //     text: 'Utenti',
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: '/azienda',
      name: 'azienda',
      component: () => import('@/views/admin/Azienda.vue'),
      meta: {
        pageTitle: 'Dati aziendali',
        // breadcrumb: [
        //   {
        //     text: 'Utenti',
        //     active: true,
        //   },
        // ],
      },
    },
    // {
    //   path: '/createUser',
    //   name: 'createUser',
    //   component: () => import('@/views/admin/createUser.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    {
      path: '/privacy-policy/:token',
      name: 'privacy',
      component: () => import('@/views/infos/PrivacyPolicy.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/terms-conditions/:token',
      name: 'terms',
      component: () => import('@/views/infos/TermsConditions.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/faq/:token?',
      name: 'faq',
      component: () => import('@/views/infos/Faq.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/tutorial/',
      name: 'tutorial',
      component: () => import('@/views/infos/Tutorial.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (
    to.name === "privacy" ||
    to.name === "terms" ||
    to.name === "faq" ||
    to.name === "register" ||
    to.name === "login" ||
    to.name === "forgotPassword" ||
    to.name === "resetPassword" ||
    to.name === "activationResend" ||
    to.name === "anonReport" ||
    to.name === "anonReportCourtesy" ||
    to.name === "submitReport"
  ) {
    if(to.name=="login" && useJwt.getToken())
    {
      return next('home')
    }
    else
    {
      return next();
    }

  }
  // console.log("Need to check authentication");
  axios.get(process.env.VUE_APP_BACKEND_API+'/v1/auth/test', {
    headers: {
      // "Access-Control-Allow-Origin": "*",
      'authorization': useJwt.getToken()
    },
  }).then(res => {
    if(res.status < 400) {
      // console.log('Valid request: ', res.data.message);
      return next();
    }
    // console.log("Go to login page");
    localStorage.clear()
    redirectToLogin(to);
  }).catch( error => {
    // console.error('Authentication error:', error);
    localStorage.clear()
    redirectToLogin(to);
  });

  function redirectToLogin(to) {
    document.cookie = `toIsSet=true; max-age=300`; // 5mins
    router.push({ name: 'login', query: { to: to.path } });
  }

});

// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

